@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), 
    local("Roboto-Thin"), 
    url("roboto-db869262e0ab09e44ec342ea616fead63f8be054.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), 
    local("Roboto-Light"), 
    url("roboto-74bf60694c9b0eb473a92eeddfbd70b736b2901e.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), 
    local("Roboto-Regular"), 
    url("roboto-7a4ddb6733c33dfe9ec94c82a5e7f5da885f5182.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), 
    local("Roboto-Medium"), 
    url("roboto-206374d86f0cff4c7a459dcad0d919180b071c5a.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), 
    local("Roboto-Bold"), 
    url("roboto-2fc9bb16fbfee39e2559e5cbf5f90b225e0a8b92.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: local("Roboto Black"), 
    local("Roboto-Black"), 
    url("roboto-2fc0a0c8e03bc268cc65b1902bcca761ea14bb98.woff") format("woff");
}

