@use '@angular/material' as mat;
@use 'angular-mgl-timeline' as mgl;
@import "./palette";
@import "./mixins";

$default-contrast: (
        contrast: (
                50: $black-87-opacity,
                100: $black-87-opacity,
                200: $black-87-opacity,
                300: $black-87-opacity,
                400: rgba(255, 255, 255, 1),
                500: rgba(255, 255, 255, 1),
                600: $white-87-opacity,
                700: $white-87-opacity,
                800: $white-87-opacity,
                900: $white-87-opacity,
                A100: $black-87-opacity,
                A200: $black-87-opacity,
                A400: rgba(255, 255, 255, 1),
                A700: $white-87-opacity,
        )
);

$ext: (
        S300 : #80C883,
        S500 : #4caf50,
        E300 : #ffbfde,
        E400 : #ff8181,
        E500 : #f44336,
        W300 : #FFD84D,
        W500 : #ffc700,
);

@mixin logi-theme($palette,$name: 'default',$dark: false) {

    $palette: map_merge($palette, $ext);
    $mat-palette: mat.define-palette($palette);
    $theme: mat.define-light-theme((
            color: (
                    primary: $mat-palette,
                    accent: mat.define-palette(mat.$yellow-palette)
            ),
            density: 0
    ));
    .modal.theme-#{$name} {
        background: #ffffff;
    }
    @if $dark {
        $theme: mat.define-dark-theme((
                color: (
                        primary: $mat-palette,
                        accent: mat.define-palette(mat.$yellow-palette)
                ),
                density: 0
        ));
        .modal.theme-#{$name} {
            background: map_get(mat.$grey-palette, 800);
            color: #ffffff;
        }
    }

    .confirm {
        .color-warn {
            color: map_get($palette, E500);
        }
    }
    common-table tr.mat-accent,
    common-table2 tr.mat-accent {
        background-color: map_get($palette, W300);
    }
    common-table tr.mat-primary,
    common-table2 tr.mat-primary {
        background-color: #bdffaa;
    }
    common-table tr.mat-danger,
    common-table2 tr.mat-danger {
        background-color: map_get($palette, E400);
    }

    .theme-#{$name} {
        @if ($name == 'default') {
            @include mat.core-theme($theme);
            $isDefaultTheme: false;
        }
        @if ($name != 'default') {
            @include mat.all-component-colors($theme);
        }
        @include mgl.timeline-theme($theme);
    }
    @include logi-theme-ext($palette, $name, $dark);
}

//default theme
@include mat.core();
@include mat.all-component-typographies();
$palette: mat.define-palette(map-merge($mat-logivice, $default-contrast));
//$theme1: mat-light-theme($palette1, mat-palette($mat-yellow));
//@include angular-material-theme($theme1);
@include logi-theme(map-merge($mat-logivice, $default-contrast));

// grey theme
@include logi-theme($mat-grey2, 'grey');

// purple theme
@include logi-theme(mat.$purple-palette, 'purple');

// blue theme
@include logi-theme(mat.$blue-palette, 'blue');

// light-blue theme
@include logi-theme(mat.$light-blue-palette, 'light-blue');

// teal theme
@include logi-theme(mat.$teal-palette, 'teal');

// green theme
@include logi-theme(mat.$green-palette, 'green');

// brown theme
@include logi-theme(mat.$brown-palette, 'brown');

// blue-grey theme
@include logi-theme(mat.$blue-grey-palette, 'blue-grey', true);
