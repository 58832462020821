@use 'sass:math';
@use '@angular/material' as mat;
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import "./styles/theme";

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    letter-spacing: normal;
    outline: none;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar:horizontal {
    -webkit-appearance: none;
    width: 12px;
    height: 7px;
}

*::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.87);
    border-radius: 5px;
}

*::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 5px;
}

html,
body {
    height: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    outline: 0;
    font: 400 13px/18px Roboto, sans-serif;
    color: mat.get-color-from-palette($palette, 900);
}

.material-icons {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

app-login,
app-register,
app-change-password {

    display: block;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
    padding-top: 125px;

    .mat-mdc-card {

        position: relative;
        width: 352px;
        margin: 0 auto;
        padding-top: 8px;
        border-radius: 0 !important;
        background: #ffffff;
        text-align: center;

        .progress {
            position: absolute;
            top: 0;
            left: 0;
        }

        h2 {
            border-bottom: none !important;
            font-weight: 400;
            color: #3a6073;
        }

        .logo {
            display: block;
            width: 300px;
            margin: 10px auto;
        }

        form {

            width: auto;

            .mat-mdc-form-field {
                width: 100%;
            }

            .mat-mdc-raised-button {
                display: block;
                width: 40%;
                margin: 0 auto;
            }

        }

        a {
            color: inherit;
            text-decoration: none;
        }
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
        background: rgba(#3a6073, 0.87);
    }
}

.main-background,
app-entrance,
app-register,
app-login,
app-change-password {
    background: mat.get-color-from-palette($palette, 900);
    background: -webkit-linear-gradient(to left, mat.get-color-from-palette($palette, 400), mat.get-color-from-palette($palette, 900));
    background: linear-gradient(to left, mat.get-color-from-palette($palette, 400), mat.get-color-from-palette($palette, 900));
}

.light-background {
    background: mat.get-color-from-palette($palette, A400-contrast);
}

.snackbar {
    overflow: hidden;
    word-break: break-all;
    margin-top: 2px;
    border-radius: 3px;
    opacity: 0.87;

    img {
        max-width: 100%;
        width: auto;
        height: auto;
    }

    a {
        color: inherit;
    }

    &.error {
        background-color: map_get($ext, E500);
        color: rgba(black, 0.87);
    }

    &.success {
        background-color: map_get($ext, S300);
    }

    &.warning {
        background-color: map_get($ext, W500);
        color: rgba(black, 0.87);
    }

    &.closed {
        opacity: 0;
        transition: opacity 0.5s linear;
    }

    button {
        color: rgba(white, 0.87);
        background-color: transparent;
    }
}

.mat-mdc-card {
    padding: 16px;

    .mat-mdc-card-content {
        padding: 0;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}

.mdc-button {
    letter-spacing: normal !important;
    white-space: nowrap;
}

h1 {
    line-height: 1.1;
}

h2 {
    border-bottom: 1px solid rgba(0, 0, 0, .06) !important;

    .sub {
        font-size: 80%;
    }

}

h3 {
    font-weight: 500 !important;
}

.label {
    font-weight: 700;
}


button.main {
    width: 200px;
}

form,
.form {

    position: relative;
    max-width: 600px;
    padding: 0 10px;
    margin: 0 auto;

    &.transparent {
        max-width: none;
        padding: 0;
        margin: 0;
        position: static;
    }

    .mat-form-field-placeholder-wrapper {
        top: -1.1em;
    }

    .mat-select {

        padding-top: 0 !important;

        .mat-select-placeholder {
            top: -4px;
        }

        .mat-select-trigger {
            font-weight: 400 !important;

            .mat-select-underline {
                bottom: 2px;
            }

        }

    }

    .single {
        width: 100%;
        margin-bottom: 24px;
    }

    .group {
        width: 100%;
        min-height: 50px;
        margin-bottom: 24px;

        &.flex {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
        }

        &.align-top {
            align-items: flex-start;
        }

        .full {
            width: 100% !important;
        }

        .half {
            width: percentage(math.div(0.99, 2)) !important;
        }

        .third {
            width: percentage(math.div(0.98, 3)) !important;
        }

        .third2 {
            width: percentage(math.div(0.98, 3)*2+0.01) !important;
        }

        .quarter {
            width: percentage(math.div(0.97, 4)) !important;
        }

        .quarter3 {
            width: percentage(math.div(0.97, 4)*3+0.02) !important;
        }

        .filler {
            min-height: 1px;
        }

        .max {
            flex-grow: 1;
        }

        .min {
            flex-shrink: 1;
        }
    }

    .actions {
        width: 100%;
        padding-bottom: 20px;

        button + button {
            margin-left: 20px;
        }
    }

    input[readonly] {
        color: rgba(0, 0, 0, 0.5) !important;
    }
}

.clear {
    clear: both;
}

.pointer {
    cursor: pointer;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.text-right,
.ql-align-right {
    text-align: right !important;
}

.text-left,
.ql-align-left {
    text-align: left !important;
}

.text-center,
.ql-align-center {
    text-align: center !important;
}

.text-justify,
.ql-align-justify {
    text-align: justify !important;
}

.text-nowrap {
    white-space: nowrap;
}

.scroll {
    overflow: auto;
}

.scroll-x {
    overflow-x: auto;
    overflow-y: hidden;
}

.scroll-y {
    overflow-y: auto;
    overflow-x: hidden;
}

.vertical-middle {
    vertical-align: middle !important;
}

.vertical-top {
    vertical-align: top !important;
}

.vertical-bottom {
    vertical-align: bottom !important;
}

.padding-0 {
    padding: 0 !important;
}

.padding-10 {
    padding: 10px !important;
}

.padding-top-0 {
    padding-top: 0 !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-top-10 {
    padding-top: 10px !important;
}

.padding-top-20 {
    padding-top: 20px !important;
}

.padding-left-0 {
    padding-left: 0 !important;
}

.padding-left-10 {
    padding-left: 10px !important;
}

.padding-left-20 {
    padding-left: 20px !important;
}

.padding-right-0 {
    padding-right: 0 !important;
}

.padding-right-10 {
    padding-right: 10px !important;
}

.padding-right-20 {
    padding-right: 20px !important;
}

.padding-bottom-0 {
    padding-bottom: 0 !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.padding-bottom-10 {
    padding-bottom: 10px !important;
}

.padding-bottom-20 {
    padding-bottom: 20px !important;
}

.margin-bottom-0 {
    margin-bottom: 0 !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-bottom-40 {
    margin-bottom: 40px !important;
}

.margin-top-0 {
    margin-top: 0 !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-right-0 {
    margin-right: 0 !important;
}

.margin-right-10 {
    margin-right: 10px !important;
}

.margin-right-20 {
    margin-right: 20px !important;
}

.margin-left-0 {
    margin-left: 0 !important;
}

.margin-left-10 {
    margin-left: 10px !important;
}

.margin-left-20 {
    margin-left: 20px !important;
}

.border-bottom-none {
    border-bottom: none !important;
}

.relative {
    position: relative !important;
}

.color-accent {
    color: map_get(mat.$yellow-palette, 500);
}

.color-warn {
    color: map_get(mat.$red-palette, 500);
}

.color-info {
    color: map_get(mat.$blue-palette, 500);
}

.text-underline {
    text-decoration: underline !important;
}

.background-accent {
    background-color: map_get(mat.$yellow-palette, 500);
}

.background-warn {
    background-color: map_get(mat.$red-palette, 500);
}

.background-danger {
    background-color: map_get($ext, E500);
}

.background-success {
    background-color: map_get($palette, S500);
}

.background-info {
    background-color: map_get(mat.$blue-palette, 500);
}


.flex {
    display: flex;

    &.row {
        flex-flow: row wrap;
    }

    &.col {
        flex-direction: column;
    }

    &.no-wrap {
        flex-wrap: nowrap;
    }

    &.align-top {
        align-items: flex-start;
    }

    &.align-center {
        align-items: center;
    }

    &.align-bottom {
        align-items: flex-end;
    }

    &.space-between {
        justify-content: space-between;
    }

    &.justify-stretch {
        justify-content: stretch;
    }

    &.content-stretch {
        align-content: stretch;
    }

    > * {
        &.full {
            width: 100% !important;
        }

        &.half {
            width: percentage(math.div(0.99, 2)) !important;
        }

        &.third {
            width: percentage(math.div(0.98, 3)) !important;
        }

        &.third2 {
            width: percentage(math.div(0.98, 3)*2+0.01) !important;
        }

        &.quarter {
            width: percentage(math.div(0.97, 4)) !important;
        }

        &.quarter3 {
            width: percentage(math.div(0.97, 4)*3+0.02) !important;
        }

        &.filler {
            min-height: 1px;
        }

        &.max {
            flex-grow: 1;
        }

        &.min {
            flex-shrink: 1;
        }
    }
}


.chart {
    padding: 2px;

    &.calendar {
        div {
            margin: 0 auto;
        }
    }
}

.cdk-overlay-backdrop {
    background: rgba(black, 0.75);

    &:before {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        content: 'close';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;
        font-size: 48px;
        color: #ffffff;
        cursor: pointer;
        font-family: 'Material Symbols Outlined';
    }
}

.modal {
    display: block;
    position: relative !important;
    overflow: auto;
    padding: 24px;
    outline: 0;
    box-sizing: border-box;
    box-shadow: 0 11px 15px -7px rgba(white, .2),
    0 24px 38px 3px rgba(0, 0, 0, .14),
    0 9px 46px 8px rgba(0, 0, 0, .12);
    border-radius: 2px;
    background: #fff;

    .container-heading {
        margin: 10px 0 35px 0;
        font: 100 34px/40px Roboto, sans-serif;
    }

    mat-card {
        padding: 0 !important;
        box-shadow: none !important;
    }

    button {
        margin-right: 5px;
    }

}

common-table2 {
    span.sort {
        display: block;
        padding-right: 20px;
    }
}

.table-wrapper {
    overflow: auto;
    width: 100%;
}

.table {
    clear: both;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px !important;

    th,
    td {
        box-sizing: content-box;
        margin: 0;
        padding: 8px 12px !important;
        outline: none;
        border-bottom: 1px solid rgba(0, 0, 0, .06);
        line-height: 30px;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.action {
            padding: 8px 0 !important;

            button,
            a {
                margin: 0 4px;
            }
        }

        .mat-icon {
            vertical-align: middle;
        }

        img {
            width: auto;
            height: auto;
            max-height: 36px;
            vertical-align: middle;
        }
    }

    tfoot {
        th,
        td {
            border-bottom: none;
        }
    }

    th {
        &.sorting,
        &.sorting_asc,
        &.sorting_desc,
        span.sort {
            cursor: pointer;
        }
    }

    button {
        display: inline-block;

        &.mat-mdc-mini-fab {
            line-height: 36px;
            width: 32px;
            height: 32px;


            .mat-icon {
                font-size: 18px;
                vertical-align: middle;
            }

            .fa {
                font-size: 18px;
                vertical-align: baseline;
            }
        }
    }

    th.row-actions,
    td.row-actions {
        width: 1%;
        text-align: right;
        white-space: nowrap;
    }

    tr.show-child + tr {
        > td {
            padding: 0 !important;
            border-bottom: none !important;

            > div {
                padding: 8px 12px;

                table {
                    margin: 0 !important;
                }
            }
        }

    }

    a {
        color: inherit;
        text-decoration: none;
    }

    .passed {
        color: map_get($ext, S500);
    }

    .failed {
        color: map_get($ext, E500);
    }

}

.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    box-shadow: 0 3px 1px -2px #000,
    0 2px 2px 0 #000,
    0 1px 5px 0 #000;
    padding: 10px;
    height: auto !important;
    background: #ffffff;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    border-radius: 4px;
    cursor: move;
}

.gu-hide {
    display: none !important;
}

.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.gu-transit {
    opacity: 0.2;
}

[role="button"] {
    cursor: pointer;
}

.mat-autocomplete-panel,
.mat-mdc-autocomplete-panel,
.mat-mdc-dialog-container,
.mat-mdc-menu-panel,
.cdk-overlay-pane,
.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
    background: #ffffff;
}

.mat-mdc-menu-panel {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.87);

    .mat-mdc-menu-content {
        padding: 0;
    }

    .mat-mdc-menu-item {
        &:hover {
            background: rgba(black, 0.06);
        }
    }
}

.mat-autocomplete-panel,
.mat-mdc-autocomplete-panel,
.mat-mdc-select-panel {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.87);

    .mat-mdc-option {
        font-size: 14px;

        &:hover {
            background: rgba(black, 0.06);
        }

        &.mat-mdc-option-active {
            background: rgba(black, 0.1);
        }

        &.bold {
            font-weight: 700;
        }

        &.border-bottom {
            border-bottom: 1px solid rgba(black, 0.87);
        }
    }
}

.mat-mdc-select-panel .mat-optgroup-label,
.mat-mdc-select-panel .mat-mdc-option {
    height: 39px !important;
    line-height: 39px !important;
    letter-spacing: normal;

    .mdc-list-item__primary-text {
        line-height: 1.2;
        white-space: nowrap;
    }
}

.cdk-overlay-pane {
    background: transparent;
}

.mat-calendar-body-cell-content {
    border: none !important;
}

.mat-calendar-body-cell-content:hover,
.mat-calendar-body-selected {
    background: #e7ecee;
}

.mat-calendar-next-button,
.mat-calendar-previous-button,
.mat-calendar-period-button {
    background: none;
}

.mat-tab-label {
    .material-icons {
        font-size: 20px;
        height: 20px;
        vertical-align: text-bottom;
    }
}

.mat-mdc-option[aria-disabled=true] {
    background: #F0F0F0;
    color: rgba(black, 0.5);
}

.mat-chip,
.mat-chip:not(.mat-basic-chip) + .mat-chip:not(.mat-basic-chip) {
    margin: 0 8px 2px 0 !important;
}

.mat-chip-row,
.mat-chip-row:not(.mat-basic-chip) + .mat-chip-row:not(.mat-basic-chip) {
    margin: 0 8px 2px 0 !important;
}

.mat-tooltip,
.mdc-tooltip,
.mat-mdc-tooltip {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel {
    .mat-expansion-panel-header {
        .mat-content {
            align-items: center;
        }
    }
}

.chip {
    display: inline-block;
    vertical-align: middle;
    padding: 3px 5px;
    border-radius: 5px;
}

section-profile,
section-user-view,
section-partner-settings,
section-warehouse-settings,
section-admin-report-view {

    .columns-container {
        display: flex;
        flex-flow: row;
        justify-content: space-between;

        > div {
            &.column-1-3 {
                width: 33.3333%;
            }

            &.column-2-3 {
                width: 66.6666%;
            }

            &.column-1-2 {
                width: 50%;
            }

            > .mat-mdc-card {
                margin: 0 10px 20px;
            }
        }
    }

    .avatar {
        img {
            width: auto;
            height: auto;
            max-width: 100%;
        }

        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto 20px;
        border: 1px solid #cccccc;
        background: url("./assets/images/default-avatar.png") 50% 50% no-repeat;
    }

    .logo {
        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .name {
        text-align: center;
        font-weight: 700;
        font-size: 18px;

        .contact {
            font-weight: 400;
            text-align: center;
            opacity: 0.75;
            font-size: 14px;
            margin-top: 10px;

            & + .contact {
                margin-top: 5px;
            }
        }
    }

    .nav-list {
        .mat-divider {
            position: relative;
        }

        .mat-list-item {
            cursor: pointer;

            &.active {
                background: rgba(black, 0.25);
            }
        }
    }

    .mat-tab-body-content {
        .content {
            padding: 2px 0;
        }
    }

    a {
        color: inherit;
    }

    .add-form {
        .mat-subheader {
            padding: 16px 0 0;
        }

        .search {
            width: 250px;
        }
    }

}

.notifications-menu {
    .notification {
        .mat-icon {
            &.error {
                color: map_get($ext, E500);
            }

            &.success {
                color: map_get($ext, S500);
            }

            &.warning {
                color: map_get($ext, W500);
            }
        }

        i {
            span {
                background: map_get($ext, E500);
            }
        }
    }
}

.mat-cell {
    .mat-icon {
        vertical-align: middle;
    }
}

.mat-icon {
    &.md-48 {
        font-size: 48px;
        width: 48px;
        height: 48px;
    }
}

.mat-select-search-inner {
    background: #ffffff;
}

section-dashboard .requests .status,
section-profile .requests .status,
section-requests-list .status,
section-order-list-by-item .table .status,
section-requests-list .status,
section-followups-list .status,
section-requests-view .status .status-chip,
section-followups-view .status .status-chip {
    display: inline-block;
    padding: 0 5px;
    line-height: 1.3;
    border-radius: 3px;
    color: #ffffff;
    background: #2ecc71;

    &.Line.created,
    &.New {
        background: #3498db;
    }

    &.Open {
        background: #2980b9;
    }

    &.Pending {
        background: #95a5a6;
    }

    &.Shipped,
    &.On-hold {
        background: #7f8c8d;
    }

    &.In-progress {
        background: #FFD84D;
    }

    &.Solved,
    &.Line.Scheduled {
        background: #2ecc71;
    }

    &.inbound,
    &.Closed {
        background: #27ae60;
    }

    &.Exception,
    &.outbound {
        background: #e74c3c;
    }

}

.actions {
    .button-group {
        margin-right: 10px;
    }
}

.button-group {
    display: inline-block;
    white-space: nowrap;

    button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: 0 !important;

        & + button {
            margin-left: 0 !important;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            min-width: 5px;

            > .mat-icon {
                margin-left: 0;
                margin-right: 0;
            }
        }

        &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        &.icon {
            padding: 0 5px;
            min-width: 0;
        }
    }
}

.fa {
    width: 24px;
    font-size: 20px;
    vertical-align: middle;
    text-align: center;
    height: 24px;
    line-height: 24px;
}

.hidden {
    display: none !important;
}

.filter-plus-content {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    > div {
        flex-grow: 1;
        flex-shrink: 1;
        width: auto;
        overflow: hidden;

        &.filter-container {
            overflow: hidden !important;
            width: 0 !important;
            flex-shrink: 0 !important;
            transition: width 0.5s;
            flex-grow: 1 !important;
            visibility: visible;
            height: 0;

            > div {
                opacity: 0;
                transition: opacity 0.5s;
            }

            &.open {
                width: 280px !important;
                transition: width 0.5s;
                height: auto;

                > div {
                    opacity: 1;
                    transition: opacity 0.5s;
                }
            }
        }

        &.content-container {

        }
    }
}


.not-is-prod-server {
    position: fixed;
    top: 20px;
    left: 50%;
    padding: 0 5px;
    background: #f44336;
    color: #ffffff;
    font-size: 16px;
    border-radius: 10px;
    z-index: 10;
    margin-left: -150px;
    opacity: 0.75;
    visibility: unset;
    pointer-events: none;
}

.mat-step-icon,
.mat-step-icon-not-touched {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mat-autocomplete-panel,
.mat-mdc-autocomplete-panel,
.mat-select-content,
.mat-mdc-select-value-text {
    .mat-option-text,
    mat-select-trigger {
        img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            width: 25px;
            height: auto;
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
        }
    }
}

section-procedures-outbound,
section-warehouse-order-wizard {
    .container-columns {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: space-between;

        .not-shipped {
            padding-top: 15px;
        }

        > mat-card {
            width: 45%;

            &.right {
                .sticky {
                    position: -webkit-sticky;
                    position: sticky;
                    top: 80px;
                }
            }

            .item,
            .transaction {
                padding: 10px;
                margin-bottom: 5px;
                box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2),
                0 2px 2px 0 rgba(0, 0, 0, .14),
                0 1px 5px 0 rgba(0, 0, 0, .12);
                border-radius: 4px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .transaction {
                background: #ffffff;
                color: rgba(0, 0, 0, 0.87);
            }

            .parcel {
                & + .parcel {
                    margin-top: 20px;
                }
            }

            .item {
                & + .item {
                    margin-top: 20px;
                }

                .transactions {
                    margin-top: 10px;
                }
            }

            .transactions {
                padding: 10px 10px 10px 30px;
                background: #E0E0E0;
                border-radius: 5px;
                min-height: 50px;
                border: 2px dashed #cccccc;

                .transaction {
                    cursor: move;
                }
            }

            .transaction {
                background-image: url("/assets/images/transaction.png");
                background-position: 90% 10px;
                background-repeat: no-repeat;
            }

            .shipment {
                background-image: url("/assets/images/shipment.png");
                background-position: 10px 10px;
                background-repeat: no-repeat;

                .title {
                    min-height: 70px;
                    padding-left: 70px;
                }
            }

            .item .title,
            .item .transaction .title,
            .transactions .transaction .title {
                display: flex;
                flex-flow: row wrap;

                > span {
                    display: block;
                    width: 50%;
                }
            }
        }
    }
}

button {
    &.disabled {
        background-color: #cccccc !important;
        color: #999999 !important;
        box-shadow: none !important;
    }

    &.button-timer {
        padding: 0 !important;

        .button-timer-content {
            display: block;
            position: relative;
            overflow: hidden;
            border-radius: 4px;

            .button-timer-infill {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                right: 100%;
                z-index: 1;
                background: rgba(0, 0, 0, 0.5);
                animation: fill 20s linear;
            }

            .button-timer-text {
                width: 100%;
                display: block;
                position: relative;
                z-index: 2;
            }
        }

    }

    .mdc-button__label {
        white-space: nowrap;
    }
}

mat-form-field.ng-invalid label .mat-form-field-required-marker {
    color: #f44336 !important;
}

.mat-mdc-select-panel {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
}

//.mat-mdc-checkbox {
//    padding-top: 15px;
//}

.slide-out-top {
    -webkit-animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.icon-inline {
    font-size: inherit;
    width: auto;
    height: auto;
    display: inline;
}


.icon-compose {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;

    .main {
        position: relative;
        vertical-align: middle;
    }

    .additional {
        position: absolute;
        width: auto;
        height: auto;
        font-size: 13px;
    }
}

.mat-spinner {
    circle {
        stroke: #005D94 !important;
    }
}


$timeline-height: 25px;

.timeline {
    position: relative;
    overflow: hidden;
    height: $timeline-height;
    line-height: $timeline-height;
    border-radius: 20px;
    min-width: 100px;
    cursor: pointer;
    display: block;
    vertical-align: middle;

    .progress {
        position: relative;
        height: $timeline-height;
        overflow: hidden;
        left: 0;
        z-index: 1;
        width: 0;
    }

    .dates,
    .distance {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        text-align: center;
        z-index: 2;
        font-size: 12px;
        transition: opacity 1s;
    }

    .distance {
        opacity: 0;
    }

    &:hover {
        .dates {
            transition: opacity 1s;
            opacity: 0;
        }

        .distance {
            transition: opacity 1s;
            opacity: 1;
        }
    }

    &.green {
        background-color: #80C883 !important;

        .progress {
            background-color: #4caf50 !important;
        }
    }

    &.yellow {
        background-color: #FFD84D !important;

        .progress {
            background-color: #ffc700 !important;
        }
    }

    &.red {
        background-color: #ffbfde !important;

        .progress {
            background-color: #f44336 !important;
        }
    }
}

.mat-icon.mat-success {
    color: map_get($ext, S500);
}

.button-150,
.button-200,
.button-250 {
    width: 150px;
    text-align: center;
    overflow: hidden !important;
    text-overflow: ellipsis;
}


.button-200 {
    width: 200px;
}

.button-250 {
    width: 250px;
}

.icon-small {
    width: 18px !important;
    height: 18px !important;
    font-size: 18px !important;
    line-height: 18px !important;
    vertical-align: text-bottom !important;
}

.mat-datepicker-dialog {
    .mat-mdc-dialog-container {
        padding: 0 !important;

        ngx-mat-datetime-content.mat-datepicker-content {
            border-radius: 0 !important;
            box-shadow: none !important;
            min-height: 312px;
            max-height: 788px;
            min-width: 250px;
            max-width: 750px;
            display: flex;
            flex-flow: column;
            justify-content: space-between;

            @media (orientation: landscape) {
                width: 40vh;
                //height: 67vh;
            }

            .mat-calendar {
                width: 100%;

                div.mat-calendar-arrow {
                    width: auto;
                    border-width: 10px 10px 0 10px;
                }
            }

            .ngx-mat-timepicker {
                .ngx-mat-timepicker-table {
                    margin: 0 auto;

                    .mat-icon-button {
                        width: 44px;
                        height: 44px;
                    }
                }

                .mat-form-field-appearance-legacy {
                    .mat-mdc-text-field-wrapper {
                        padding-bottom: 0;
                    }

                    .mat-form-field-infix {
                        padding: 0 !important;
                        border-top: none !important;
                    }
                }
            }

            .mat-stroked-button {
                border: none;
            }
        }

    }
}

.mat-datepicker-content-touch {
    background-color: #fff;
}

.mat-calendar-body-disabled {
    color: rgba(0, 0, 0, 0.3);
}

.mat-badge {
    &.mat-badge-small,
    &.mat-badge-medium,
    &.mat-badge-large {
        &.mat-badge-inset {
            &.mat-badge-above {
                .mat-badge-content {
                    top: 0;
                }
            }

            &.mat-badge-below {
                .mat-badge-content {
                    bottom: 0;
                }
            }

            &.mat-badge-before {
                .mat-badge-content {
                    left: 0;
                }
            }

            &.mat-badge-after {
                .mat-badge-content {
                    right: 0;
                }
            }
        }
    }
}

.common-alert {

}

img.responsive {
    max-width: 100%;
    width: auto;
    height: auto;
}

.blink {
    animation: blink-animation 1s infinite;
}

.mat-mdc-list-item {
    &.active {
        background-color: rgba(0, 0, 0, 0.05);
    }
}

body {
    .mdc-text-field {
        overflow: visible;

        input {
            letter-spacing: normal !important;
        }


        &.mat-mdc-text-field-wrapper {
            padding: 20px 0 0;
        }

        .mat-mdc-form-field-icon-prefix > .mat-icon,
        .mat-mdc-form-field-icon-suffix > .mat-icon {
            padding: 0;
        }

        &.mdc-text-field--filled {
            .mdc-floating-label.mdc-floating-label--float-above {
                top: 0;
            }
        }

        .mat-mdc-form-field-icon-suffix, [dir=rtl] .mat-mdc-form-field-icon-prefix {
            padding-left: 0px;
        }

        //.mat-mdc-form-field-subscript-wrapper {
        //    height: 10px;
        //}

        .mat-mdc-floating-label {
            width: 100%;
        }
    }

    .no-label {
        .mdc-text-field {
            &.mat-mdc-text-field-wrapper {
                padding: 0;
            }
        }

        .mdc-floating-label,
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }
}


body .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
    transform: none;
}

.mat-expansion-panel-header {
    height: 48px;
}

.lh-48 {
    line-height: 48px;
}

.mat-menu-delete-button {
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 10px;
    margin: 0 !important;
    background-color: #fff;

    &:hover {
        background-color: #ddd;
    }
}

body .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: 0;
}

.mdc-text-field--disabled.mdc-text-field--filled {
    background-color: transparent !important;
}

.mat-mdc-mini-fab {
    &.spin {
        mat-icon {
            animation: spin linear 1s infinite;
            transform-origin: center 9px;
        }
    }
}

input[aria-hidden=true],
.mat-select-search-input[aria-hidden=true],
.mat-select-search-hidden {
    display: none !important;
    visibility: hidden !important;
}


common-form-error {
    .input-form-error {
        color: map_get($ext, E500);
    }
}

@keyframes fill {
    from {
        right: 100%;
    }
    to {
        right: 0;
    }
}

@-webkit-keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
}

@keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
}


@keyframes blink-animation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}