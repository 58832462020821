@mixin logi-theme-ext($palette,$name,$dark) {

    app-drawer.theme-#{$name} {
        .ribbon {
            background: map-get($palette, 900);
            background: -webkit-linear-gradient(to left, map-get($palette, 400), map-get($palette, 900));
            background: linear-gradient(to left, map-get($palette, 400), map-get($palette, 900));
        }

        .mat-mdc-raised-button.mat-success,
        .mat-mdc-fab.mat-success,
        .mat-mdc-mini-fab.mat-success {
            background: map-get($palette, S300);
            color: #ffffff;

            &:disabled {
                background: rgba(255, 255, 255, 0.12);
                color: rgba(255, 255, 255, 0.5);
            }
        }

        .mdc-text-field--filled {
            overflow: visible;

            &:not(.mdc-text-field--disabled) {
                background-color: transparent;
            }

            .mat-mdc-form-field-focus-overlay {
                background-color: transparent;
            }
        }

        .mat-icon {
            &.mat-success {
                color: map-get($palette, S500);
            }
        }

        .mat-drawer {
            background-color: map-get($palette, 700);

            #lock_drawer {
                color: #ffffff;
            }

            ul {
                li {
                    border-color: map-get($palette, 600);
                }
            }

            .link {
                color: map-get($palette, 50);

                &:hover {
                    background: map-get($palette, 800);
                }

                &.active {
                    background: map-get($palette, 900);
                }
            }

            .mat-expansion-panel {
                background: transparent;
            }

            .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-keyboard-focused,
            .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-program-focused,
            .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover,
            .mat-expansion-panel-header {
                &:hover {
                    background: map-get($palette, 800);
                }

                &.mat-expanded {
                    border-bottom-color: map-get($palette, 600);
                }

                .mat-expansion-panel-header-title {
                    color: map-get($palette, 50);
                }
            }

            .mat-expansion-indicator {
                &:after {
                    color: map-get($palette, 50);
                }
            }
        }

        app-toolbar {
            .title {
                color: map-get($palette, 800);
            }

            .order-search {
                .fa {
                    color: map-get($palette, 800);
                }
            }

            .fullscreen-mode,
            .notifications,
            .sounds,
            .user {
                color: map-get($palette, 800);
            }

            .mat-form-field-underline,
            .mat-form-field-ripple,
            .mat-focused .mat-form-field-ripple {
                background-color: map-get($palette, 800) !important;
            }

            .mat-input-element {
                color: map-get($palette, 800);
            }

            .mat-form-field-label,
            .mat-focused .mat-form-field-label,
            .mat-mdc-select-value,
            .mat-mdc-select-arrow,
            .mat-form-field.mat-focused .mat-mdc-select-arrow {
                color: map-get($palette, 800) !important;
            }
        }

        h1.container-heading {
            color: map-get($palette, 50);
        }

        section-tracking-sidebar {
            h1.container-heading {
                color: map-get($palette, 500);
            }
        }

        section-order-view {
            .references,
            .order-confirm-on {
                color: map-get($palette, 50);
            }

            .validation {
                .mat-success {
                    background: map-get($palette, S500);
                    color: #ffffff;
                }
            }

            .order-status {
                .mat-mdc-select-arrow,
                .mat-mdc-select-value,
                .mat-form-field-label {
                    color: map-get($palette, 50) !important;
                }

                .mat-form-field-underline,
                .mat-form-field-ripple {
                    background-color: map-get($palette, 50);
                }
            }
        }

        section-tracking-list {
            table {
                .mat-button {
                    border-bottom-color: map-get($palette, 500);
                }
            }

            .track-status {
                color: grey;

                &.in.transit,
                &.out.for.delivery {
                    color: #ffffff;
                    background: map-get($palette, W300);
                }

                &.exception,
                &.expired {
                    color: map-get($palette, E500);
                }

                &.delivered {
                    color: map-get($palette, S300);
                }
            }
        }

        common-counter {
            .progress {
                background: map-get($palette, 100);

                div {
                    background: map-get($palette, 500);
                    color: map-get($palette, 100);
                }
            }

            .counter {
                > div {
                    background: map-get($palette, S300);
                    color: #ffffff;

                    > span {
                        &:first-child {
                            background: map-get($palette, S500);
                        }
                    }
                }

                &.expired {
                    > div {
                        background: map-get($palette, E300);

                        > span {
                            color: rgba(black, 0.87);

                            &:first-child {
                                color: #ffffff;
                                background: map-get($palette, E500);
                            }
                        }
                    }
                }
            }

        }

        section-order-view-items table button.status,
        section-warehouse-procedures-wizard-scan-default-results table button.status,
        common-table tr.show-child + tr > td > div,
        section-adjust-transactions-view .item,
        section-warehouse-order-wizard .item {
            background-color: #ffffff;
            color: rgba(0, 0, 0, 0.87);

            &.inbounded,
            &.shipped,
            &.packed,
            &.closed {
                background-color: map-get($palette, S300);
            }

            &.awaiting.inbound {
                background-color: #a7b4b0;
            }

            &.received.more.than.expected,
            &.inbounded.more.than.expected,
            &.shipped.partially,
            &.exception,
            &.sent.less.than.expected,
            &.sent.more.than.expected {
                background-color: map-get($palette, E500);
            }

            &.partially.inbounded,
            &.inbounded.less.than.expected {
                background-color: map-get($palette, W300);
            }
        }

        common-table tr.show-child + tr > td > div,
        section-adjust-transactions-view .item,
        section-warehouse-order-wizard .item {
            .mat-mdc-select-value {
                color: rgba(0, 0, 0, 0.87);
            }

            .mat-mdc-select-arrow {
                color: rgba(0, 0, 0, 0.7);
            }

            .mat-form-field-underline {
                background-color: rgba(0, 0, 0, 0.7);
            }
        }

        section-order-view {
            .references {
                .copy {
                    background: map-get($palette, 50);
                    color: map-get($palette, 500);
                    cursor: pointer;
                }
            }
        }

        @if $dark {
            .mat-mdc-card {
                background: map-get($palette, A700);
            }
            section-tracking-filter {
                .filter-content {
                    background: #263238;
                }
            }
        } @else {
            section-tracking-filter {
                .filter-content {
                    background: #E0E0E0;
                }
            }
        }

        common-section-remark,
        section-user-remark {
            .text-footer {
                color: map-get($palette, W500);
                @if $dark {
                    background: linear-gradient(to bottom,
                            rgba(map-get($palette, 700), 0.6) 0%,
                            rgba(map-get($palette, 700), 1) 100%);
                } @else {
                    background: linear-gradient(to bottom,
                            rgba(white, 0.6) 0%,
                            rgba(white, 1) 100%);
                }
            }

            @if $dark {
                .childrens {
                    background: map-get($palette, 500);

                    &:before {
                        border-bottom-color: map-get($palette, 500);
                    }
                }
            }
        }

        .chip {
            color: #ffffff;
            background: map-get($palette, 500);

            &.good {
                background: map-get($palette, S500);
            }

            &.defective {
                background: map-get($palette, E500);
            }
        }

        .mat-chip {
            &.mat-warn {
                background-color: map-get($palette, E500);
                color: #ffffff;

                .mat-chip-remove {
                    color: #ffffff;
                }
            }

            &.mat-success {
                background-color: map-get($palette, S500);
                color: #ffffff;

                .mat-chip-remove {
                    color: #ffffff;
                }
            }
        }

        .mat-chip-row {
            &.mat-warn {
                background-color: map-get($palette, E500);
                color: #ffffff;

                .mat-chip-remove {
                    color: #ffffff;
                }
            }

            &.mat-success {
                background-color: map-get($palette, S500);
                color: #ffffff;

                .mat-chip-remove {
                    color: #ffffff;
                }
            }
        }

        section-integrations-list {
            .integrations {
                > div > div {
                    background-color: map-get($palette, 800);
                    color: map-get($palette, 50);
                }
            }
        }

        section-followups-list,
        section-requests-list {
            .mat-warn {
                color: map-get($palette, E500);

                > mat-icon {
                    color: #fff;
                }
            }
        }

        section-followups-view,
        section-requests-view {
            .heading-info {
                color: map-get($palette, 50);

                .mdc-text-field:not(.mdc-text-field--disabled) {
                    .mdc-line-ripple::before {
                        border-bottom-color: rgba(255, 255, 255, 0.42);

                    }

                    &:hover {
                        .mdc-line-ripple::before {
                            border-bottom-color: rgba(255, 255, 255, 0.42);

                        }
                    }

                    .mdc-text-field__input {
                        color: map-get($palette, 50);
                    }
                }
            }


            .info-row {
                .avatar {
                    background-color: map-get($palette, 500);
                    color: map-get($palette, 50);
                }
            }
        }

    }

    .ql-stroke {
        stroke: map-get($palette, 500) !important;
    }

    .user-menu.theme-#{$name} {
        .user-menu-item {
            color: map-get($palette, 800) !important;

            span {
                color: inherit;
            }

            &:not(:first-child) {
                border-color: rgba(black, .06);
            }

            .mat-icon {
                color: map-get($palette, 800) !important;
            }
        }
    }
    .notifications-menu.theme-#{$name} {
        .mat-list-item-content {
            color: map-get($palette, 800) !important;
        }
    }

    .modal.theme-#{$name} {
        section-part-master-view {
            .missing {
                color: map-get($palette, E500);
            }
        }

        common-file-upload {
            .errors {
                background: map-get($palette, E300);
                border-color: map-get($palette, E500);
            }
        }

        .mat-chip {
            &.mat-warn {
                background-color: map-get($palette, E500);
                color: #ffffff;

                .mat-chip-remove {
                    color: #ffffff;
                }
            }

            &.mat-success {
                background-color: map-get($palette, S500);
                color: #ffffff;

                .mat-chip-remove {
                    color: #ffffff;
                }
            }
        }

        .mat-chip-row {
            &.mat-warn {
                background-color: map-get($palette, E500);
                color: #ffffff;

                .mat-chip-remove {
                    color: #ffffff;
                }
            }

            &.mat-success {
                background-color: map-get($palette, S500);
                color: #ffffff;

                .mat-chip-remove {
                    color: #ffffff;
                }
            }
        }

        .mdc-text-field--filled {
            overflow: visible;

            &:not(.mdc-text-field--disabled) {
                background-color: transparent;
            }

            .mat-mdc-form-field-focus-overlay {
                background-color: transparent;
            }
        }
    }


    .mat-mdc-select-panel .mat-mdc-option {
        &.good {
            background-color: rgba(map-get($palette, S500), 0.5);
        }

        &.defective {
            background-color: rgba(map-get($palette, E500), 0.5);
        }
    }

    .mat-icon-button.mat-success {
        color: map-get($palette, S500);
    }

    .text-warn {
        color: map-get($palette, W500) !important;
    }
    .text-error {
        color: map-get($palette, E500) !important;
    }
    .text-success {
        color: map-get($palette, S500) !important;
    }
    .text-disabled {
        color: map-get($palette, 300) !important;
    }
    .text-inverse {
        color: map-get($palette, 50) !important;
    }

    .background-warn {
        background-color: map-get($palette, W300) !important;
    }
    .background-error {
        background-color: map-get($palette, E300) !important;
    }
    .background-success {
        background-color: map-get($palette, S300) !important;
    }

    mat-form-field.ng-invalid label {
        color: map-get($palette, E500) !important;
    }

    common-signature-pad {

        .signature.required {
            border: 1px solid map-get($palette, E500);
        }

        .signature.saved {
            border: 1px solid map-get($palette, S500);
        }
    }


    .theme-#{$name} {

        @if $dark {
            a {
                color: map-get($palette, W300);
            }
            .table {
                th.sorting {
                    background: url("../assets/images/icons/table/sort-dark.svg") no-repeat center right !important;
                }

                th.sorting_asc {
                    background: url("../assets/images/icons/table/sort-asc-dark.svg") no-repeat center right !important;
                }

                th.sorting_desc {
                    background: url("../assets/images/icons/table/sort-desc-dark.svg") no-repeat center right !important;
                }
            }

            common-table2 {
                span.sort {
                    &.disabled {
                        background: url("../assets/images/icons/table/sort-dark.svg") no-repeat center right !important;
                    }

                    &.asc {
                        background: url("../assets/images/icons/table/sort-asc-dark.svg") no-repeat center right !important;
                    }

                    &.desc {
                        background: url("../assets/images/icons/table/sort-desc-dark.svg") no-repeat center right !important;
                    }
                }

                a.tableAction {
                    color: #ffeb3b;
                }
            }

            .paginate_button {
                &.previous {
                    background: url("../assets/images/icons/table/pagination-previous-dark.svg") no-repeat 50% 50% !important;
                }

                &.next {
                    background: url("../assets/images/icons/table/pagination-next-dark.svg") no-repeat 50% 50% !important;
                }
            }
            section-order-remarks {
                .mat-checkbox-frame {
                    border-color: map-get($palette, 900);
                }
            }
            common-remark {
                .attachments {
                    background: map-get($palette, 100);
                }
            }
            input[readonly] {
                color: rgba(255, 255, 255, 0.3);
            }
        } @else {
            .table {
                th.sorting {
                    background: url("../assets/images/icons/table/sort.svg") no-repeat center right !important;
                }

                th.sorting_asc {
                    background: url("../assets/images/icons/table/sort-asc.svg") no-repeat center right !important;
                }

                th.sorting_desc {
                    background: url("../assets/images/icons/table/sort-desc.svg") no-repeat center right !important;
                }
            }

            common-table2 {
                span.sort {
                    &.disabled {
                        background: url("../assets/images/icons/table/sort.svg") no-repeat center right !important;
                    }

                    &.asc {
                        background: url("../assets/images/icons/table/sort-asc.svg") no-repeat center right !important;
                    }

                    &.desc {
                        background: url("../assets/images/icons/table/sort-desc.svg") no-repeat center right !important;
                    }
                }
            }


            .paginate_button {
                &.previous {
                    background: url("../assets/images/icons/table/pagination-previous.svg") no-repeat 50% 50% !important;
                }

                &.next {
                    background: url("../assets/images/icons/table/pagination-next.svg") no-repeat 50% 50% !important;
                }
            }

            tr.show-child + tr > td > div {
                background: #EDF1F2;

                table {
                    background: #ffffff;
                }
            }
        }

        section-inventory-view {
            .transactions .transaction > div.typicon {
                &.inbound {
                    color: map-get($palette, S500);
                }

                &.outbound {
                    color: map-get($palette, E500);
                }
            }
        }

        common-table {
            .selected .row-selections span:before {
                color: #ffeb3b;
            }

        }

        common-table,
        section-requests-view,
        section-followups-view,
        common-table2 {
            .timeline {
                background-color: map-get($palette, 900);

                .progress {
                    background-color: map-get($palette, 400);
                }

                .dates,
                .distance {
                    color: map-get($palette, 50);
                }
            }

            thead {
                .infinity-scroll-footer {
                    @if $dark {
                        background-color: #455a64;
                    } @else {
                        background-color: #ffffff;
                    }
                }

                .table-header-title {
                    @if $dark {
                        background-color: #3e515a;
                    } @else {
                        background-color: #fbfbfb;
                    }
                }
            }
        }

        common-form-switch {
            .switch {
                &.off {

                    > .switch-item {
                        color: map-get($palette, 500);
                    }

                    > .itemFirst {
                        color: #ffffff;
                    }
                }

                &.on {
                    > .switch-item {
                        color: map-get($palette, 500);
                    }

                    > .itemSecond {
                        color: #ffffff;
                    }
                }

                &.off,
                &.on {
                    border: 1px solid map-get($palette, S300);
                }

                &.trueFalse {
                    &.off {

                        > .switch-item {
                            color: map-get($palette, S300);
                        }

                        > .itemFirst {
                            color: #ffffff;
                        }
                    }

                    &.on {
                        > .switch-item {
                            color: map-get($palette, S300);
                        }

                        > .itemSecond {
                            color: #ffffff;
                        }
                    }
                }
            }
        }

        .mat-badge-content {
            border-radius: 5px;
            padding: 0 3px;
            min-width: 22px;
            width: auto !important;
        }

        mgl-timeline-entry-dot {
            &.warn {
                background-color: map-get($palette, E500);
            }

            &.accent {
                mat-icon {
                    color: #000000;
                }
            }

        }

        .mat-button-toggle-checked {
            background-color: map-get($palette, 500);
        }

        .mat-mdc-standard-chip {
            --mdc-chip-elevated-container-color: #F3F0F0;
            --mdc-chip-elevated-disabled-container-color: #F3F0F0;
            --mdc-chip-label-text-color: #000;
            --mdc-chip-disabled-label-text-color: #000;
            --mdc-chip-with-icon-icon-color: #000;
            --mdc-chip-with-icon-disabled-icon-color: #000;
            --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #000;
            --mdc-chip-with-trailing-icon-trailing-icon-color: #000;
            --mdc-chip-with-icon-selected-icon-color: #000;
        }

        .mat-mdc-chip-remove {
            opacity: 1;
        }

    }
}
